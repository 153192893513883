.events-container {
    font-family: Arial, sans-serif;
    color: #333;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slideshow{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  width: 100%;
}

  .event-container h2{
    font-size: xx-large;
  }
  
  .event-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 94%;
    padding: 3%;
    background-color: #ffffff;
  }
  
  .event-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 3%;
    margin: 1% 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 60%;
  }
  
  .event-date, .event-place, .event-name {
    margin: 5px 0;
  }
  
  .event-date {
    color: #333;
  }
  
  .event-place {
    color: #dfa42e;
  }
  
  .event-name {
    color: #17a2b8;
  }
  
  .event-item .fa-icon {
    margin-right: 8px;
  }

  .event{
    padding: 1%;
  }

  .search-bar-container {
    width: 50%;
    padding: 2% 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-bar input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0px 10px;
    width: 200px;
  }

  
  .search-bar-container button {
    padding: 3%;
    border: none;
    border-radius: 5px;
    background-color: #dfa42e;
    color: white;
    cursor: pointer;
  }
  
  .search-bar-container button:hover {
    opacity: 90%;
  }

  @media screen and (max-width: 768px) {
    .search-bar-container{
      width: 96%;
      padding: 2%;
    }

    .search-bar{
      display: flex;
      flex-direction: row;
      padding: 2%;
      align-items: center;
    }

    .search-bar input{
      width: 80%;
    }
    
    .search-bar-container button{
      padding: 3% 0%;
    }
    .event-item{
      width: 94%;
    }
  }
