.slideshow-container {
  position: relative;
  width: 100%;
  max-width: 80%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  opacity: 1;
  z-index: 2;
}

.navigation-arrows {
  position: absolute;
  top: 45%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  left: 0;
}

.arrow {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: color ease-in-out 0.25s;
}

.arrow:hover {
  color: #dfa42e;
  transition: color ease-in-out 0.25s;
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: 20px;
}

.dots {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #dfa42e;
}

.slide-title {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: left;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2%;
  font-size: 21px;
  z-index: 3;
}

.slide-subtitle {
  font-family: cursive;
}

@media screen and (max-width: 768px) {
  .slideshow-container{
    max-width: 100%;
  }

  .slide-title{
    padding: 0%;
    text-align: center;
  }
}
