.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1000;
  }
  
  .scroll-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 24px;
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .scroll-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
  
  .scroll-button:focus {
    outline: none;
  }
  