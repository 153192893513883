/* RepertoirePage.css */

.repertoire-page {
  font-family: Arial, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slideshow{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  width: 100%;
}

.section {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h1 {
  font-size: xx-large;
  color: #dfa42e;
  text-align: center;
}

.subsection {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.subsection a {
  text-decoration: none;
  color: #dfa42e;
  transition: color ease-in-out 0.25s;
}

.subsection a:hover {
  color: #666;
  transition: color ease-in-out 0.25s;
}

.subsection:last-child {
  border-bottom: none;
}

.subsection img {
  width: 33%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.subsection div {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.subsection h2 {
  margin: 0;
  text-transform: uppercase;
  font-size: large;
  font-size: 18px;
  color: #333;
  transition: color ease-in-out 0.25s;
}

.subsection h2:hover {
  color: #dfa42e;
  transition: color ease-in-out 0.25s;
}

.subsection p {
  text-align: justify;
  margin: 5px 0 0;
  font-size: medium;
  text-align: center;
  padding: 2% 10%;
  color: #666;
}
/* Archive Styles */
.archive {
  margin-top: 20px;
}

.section>h1{
  margin-bottom: 3%;
}

.archive>h2 {
  font-size: 20px;
  color: #333;
}

.archive-photos {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.archive-photos img {
  width: calc(25% - 10px);
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.gallery-subsection {
  padding: 3% 10px;
  border-bottom: 1px solid #e0e0e0;
}

.first-subsection {
  padding: 0% 10px 3% 10px;
}

@media screen and (max-width: 768px) {
  .subsection{
    flex-direction: column;
    padding: 5%;
  }

  .subsection img {
    width: 80%;
    margin: 0px 0px 5% 0px;
  }

  .gallery-subsection, .first-subsection{
    padding: 0%;
    padding-bottom: 5%;
  }
}
