.admin-control {
    padding: 20px;
    text-align: center;
    width: calc(100% - 40px);
    background-color: #f9f9f9;
  }
  
  .add-event-button {
    margin: 0px 20px 20px 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #dfa42e;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .event-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .controls-event-item {
    background-color: #f4f4f4;
    padding: 15px;
    margin: 10px;
    border-radius: 5px;
    width: 90%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .controls-event-item h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
  }
  
  .controls-event-item p {
    margin: 5px 0;
    font-size: 18px;
  }
  
  .controls-event-item button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    align-self: flex-end;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .controls-event-item button:hover {
    background-color: #ff1a1a;
  }
  
  @media (max-width: 600px) {
    .controls-event-item {
      width: 100%;
    }
  
    .controls-event-item h3 {
      font-size: 20px;
    }
  
    .controls-event-item p {
      font-size: 16px;
    }
  
    .controls-event-item button {
      font-size: 14px;
    }
  }
  