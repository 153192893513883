.gallery-section {
    text-align: center;
  }
  
  .gallery-section h2 {
    font-size: 20px;
    color: #333;
  }
  
  .gallery-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .gallery-images img {
    width: calc(25% - 10px);
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
  }
  
  .image-gallery {
    width: 100%;
    height: 100%;
  }
  
  .image-gallery-slide img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color ease-in-out 0.25s;
  }
  
  .close-button:hover {
    transition: background-color ease-in-out 0.25s;
    background: rgba(0, 0, 0, 0.8);
  }
  
  .image-gallery-index{
    left: 0px;
    width: 5%;
    text-align: center;
    margin: 0 auto;
  }
  .modal-content{
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
  }

  .gallery-subsection {
    text-align: center;
    margin-top: 20px;
  }
  
  .gallery-subsection h2 {
    font-size: 20px;
    color: #dfa42e;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 768px) {
    .gallery-images img{
      width: 100%;
    }
    .gallery-section{
      padding: 5%;
    }
  }