.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 100%;
}

.login-form h2 {
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: auto;
  height: 25px;
  margin-top: 5px;
  text-indent: 5px;
  font-size: 12.6pt;
  border-radius: 5px;
  border: solid 1.5px #d3d3d3;
  -webkit-transition: 1s; /* Safari */
  transition: 1s;
}

.login-button {
  width: auto;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #dfa42e;
  color: white;
  font-size: 1rem;
}

.error {
  color: red;
  margin-bottom: 1rem;
}
