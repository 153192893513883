/* DetailPage.css */

.detail-page {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    margin-bottom: 1%;
  }
  
  .header {
    position: relative;
    text-align: center;
    background-image: url("../../assets/pngegg.webp");
  }
  
  .header-image {
    width: 75%;
    height: auto;
  }
  
.header-title {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 24px;
    box-sizing: border-box;
    margin: 0px;
  }
  
  .info-section {
    margin-bottom: 20px;
    padding: 5% 10%;
  }
  
  .info-section p {
    font-size: 16px;
    color: #333;
    margin-bottom: 5%;
  }
  
  .short-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .info-item{
    padding: 25px;
    border-bottom: 1px solid #dfa42e;
  }

  .info-item:last-child{
    border-bottom: none;
  }