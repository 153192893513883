.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .add-form{
    width: auto;
  }

  .add-form label {
    display: block;
    margin: 10px 0;
  }
  
  .add-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: space-evenly;
  }
  
  .add-form button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .add-form button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    border: none;
  }
  
  .add-form button[type="button"] {
    background-color: #ff4d4d;
    color: white;
    border: none;
  }
  
  .add-form button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .add-form button[type="button"]:hover {
    background-color: #ff1a1a;
  }
  