.footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 0.5rem;
    position: relative;
    bottom: 0;
    width: calc(100% - 1rem);
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-content>p{
    margin: 0px;
  }