.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 60%;
    max-width: 50%;
    text-align: center;
    overflow-y: auto;
    max-height: 80%;
  }
  
  .popup-body {
    margin-top: 20px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .main-image {
    width: 100%;
    max-width: 400px;
    margin: 20px 0;
  }
  
  .additional-photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .additional-photos img {
    flex: 1 0 45%; /* 2 images per row with space between */
    margin: 10px;
    border-radius: 5px;
    max-width: 100%;
    height: auto;
  }
  
  @media screen and (max-width: 768px) {
    .popup-content{
      width: 95%;
      max-width: 80%;
    }
  }