.about-page {
    font-family: Arial, sans-serif;
    color: #333;
    width: 100%;
  }
  
  .banner {
    width: 100%;
    height: 500px;
    position: relative;
    text-align: center;
    color: white;
    background-color: rgba(1,1,1,0.5);
  }
  
  .image {
    width: calc(65% - 100px);
    height: 500px;
    padding: 0px 50px;
  }
  
  .history-today {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 2% 20%;
    background-color: #f4f4f4;
  }

  .history, .today {
    flex: 1;
    align-items: center;
    text-align: center;
    margin: 0 20px;
  }
  
  .team, .co-creators {
    padding: 50px;
  }

  .co-creators{
    background-color: #f4f4f4;
  }

  .co-creators>h3{
    margin-top: 2%;
  }
  
  .team-members {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .team-member, .team-member>a{
    color: black;
    max-width: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1%;
  }

  .team-member b{
    color: #dfa42e;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    z-index: 1;
  }
  
  .team-member>i {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #333;
    object-fit: cover;
  }
  
  .i-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clickable{
    color: black;
    transition: all ease-in-out 0.25s;
  }

  .clickable:hover{
    cursor: pointer;
    color: #dfa42e;
    transition: all ease-in-out 0.25s
  }
  .hover-zoom:hover{
    transform: scale(1.05);
    transition: all ease-in-out 0.25s;
  }

  @media screen and (max-width: 768px) {
    .image{
      padding: 0px;
      width: 60%;
      height: auto;
      border-radius: 15%;
    }
    .banner{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: auto;
      padding: 5%;
    }
    .history-today, .team{
      padding: 2%;
    }
    .team-members{
      flex-direction: column;
    }
    .team-member > img, .team-member > i{
      width: 250px;
      height: 250px;
    }
  }