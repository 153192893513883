.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  width: calc(100% - 40px);
  background-color: #333;
  color: #fff;
  position: relative;
  z-index: 100;
}

.login-icon{
  color: white;
}

.logo {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px;
}

.navbar-logo-mobile {
  display: none;
  height: 30px;
}

.navbar-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  position: relative;
  transition: color ease-in-out 0.25s;
}

.navbar-link:hover {
  color: #dfa42e;
  transition: color ease-in-out 0.25s;
}

.navbar-icon {
  color: #fff;
  font-size: 20px;
}

.ig:hover {
  background: -webkit-linear-gradient(rgb(188, 12, 241), rgb(212, 4, 4));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fb:hover {
  background: #1877F2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-mobile-icon {
  color: #fff;
  font-size: 20px;
}

.hamburger {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger.open {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: transform 0.3s ease;
}

.hamburger.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.navbar-mobile-links {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 60%;
  background-color: #333;
  padding: 25% 10%;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.navbar-mobile-links.open {
  right: 0;
}

.navbar-dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px 0;
  z-index: 100;
  min-width: 150px;
  flex-direction: column;
}

.dropdown-menu.show {
  display: flex;
}

.dropdown-item {
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.dropdown-item:hover {
  color: #dfa42e;
  transition: color ease-in-out 0.25s;
}

.navbar-dropdown-mobile .dropdown-menu-mobile {
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  background-color: #333;
}

.navbar-dropdown-mobile .dropdown-menu-mobile.show {
  display: flex;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .hamburger {
    display: flex;
  }

  .navbar-logo-mobile {
    display: block;
  }

  .navbar-dropdown-mobile .dropdown-menu {
    display: none;
  }

  .navbar-dropdown-mobile .dropdown-menu.show {
    display: block;
    background-color: #333;
  }

  .navbar-dropdown-mobile .dropdown-item {
    padding-left: 20px;
  }
  .link{
    text-decoration: none;
  }
}
