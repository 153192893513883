.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
  
  .contact-image img{
    width: 45%;
    max-height: 400px;
    margin-top: 5%;
  }
  
  .contact-details {
    display: flex;
    flex-direction: row;
    bottom: 0;
    height: calc(100% - 460px);
  }
  
  .contact-section {
    display: flex;
    flex-direction: column;
    padding: 2%;
    justify-content: center;
    flex: 1;
    background-color: white;
  }

  .contact-section:nth-child(3){
    background-color:  	#f4f4f4;
  }

  .map{
    padding: 0;
  }
  
  .contact-section h2 {
    margin-bottom: 15px;
  }
  
  .contact-section p {
    margin: 10px 0;
  }
  
  iframe {
    border: none;
  }

  .contact-ig {
    background: -webkit-linear-gradient(rgb(188, 12, 241), rgb(212, 4, 4));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .contact-fb {
    background: #1877F2;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .contact-section a{
    text-decoration: none;
    padding: 1%;
  }

  @media screen and (max-width: 768px) {
    .contact-image img{
      width: 100%;
      height: 100%;
      margin: 0%;
    }
    .contact-image{
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .contact-container{
      justify-content: space-evenly;
      height: fit-content;
    }
    .contact-details{
      flex-direction: column;
    }
    .contact-section{
      min-height: 300px;
    }
  }