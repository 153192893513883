.App {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  background-color: #333;
  height: 100vh;
  background-image: url(./assets/pngegg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}